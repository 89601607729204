import Vue from 'vue'

import {
    ArrowUpIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

import axios from 'axios';

/**
 * Shop checkouts component
 */

export default {
    data() {
        return {
            valid: true,
            title: null,
            systemEmail: null,
            domainName: null,
            password: null,
            comfirmPassword: null,
            show1: false,
            show2: false,
            modalRegister: false,
            textResult: null,
            overlay: false,
            requiredRules: [
                (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"
            ],
        }
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon
    },
    computed: {
        checkForm() {
            return !this.title ||
                !this.systemEmail ||
                !this.domainName ||
                !this.password ||
                !this.comfirmPassword ||
                this.comfirmPassword != this.password ||
                this.password < 8
        },
        passwordRules() {
            const rules = [];
            rules.push((v) =>
                v.length >= 8 || "กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวขึ้นไป"
            );

            return rules;
        },
        requiredRulesSubdomain() {
            const rules = [];
            rules.push((v) =>
                (/^[a-zA-Z0-9]+$/.test(v)) || "กรุณากรอกเฉพาะตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"
            );
            return rules;
        },
        exampleSubdomain() {
            const rules = [];
            rules.push((v) =>
                !(/^[a-zA-Z0-9]+$/.test(v)) || "ตัวอย่าง: " + v + ".nerdof.com"
            );
            return rules;
        },
        conFirmPasswordRules() {
            const rules = [];

            rules.push((v) =>
                v.length >= 8 || "กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวขึ้นไป"
            );
            return rules;
        },
    },
    mounted() {
        let token = this.$cookies.get("user_token") || null;
        if (token != null) {
            this.$router.replace({
                path: "/home"
            }).catch(err => {
                // do nothing
            })
        }
    },
    methods: {
        notierror(text) {
            this.$bvModal
                .msgBoxOk(
                    text,
                    {
                        title: "เกิดข้อผิดพลาด",
                        centered: true,
                    }
                )
        },
        openNewSite(newSite) {
            window.open(newSite, "_blank");
        },
        reserveCompany() {
            if (this.password == this.comfirmPassword) {
                this.overlay = true
                if (this.title && this.systemEmail && this.domainName) {
                    axios.post(`/company/manage/reserve`, {
                        title: this.title,
                        systemEmail: this.systemEmail,
                        domainName: this.domainName,
                    }).then(res => {
                        let id = res.data.result._id;

                        return this.insertUser(id)
                    }).then(resp => {
                        this.$router.replace({
                            path: "/login"
                        });
                    }).catch((err) => {
                        if (err.response) {
                            switch (err.response.data.message) {
                                case 'domain name is already in used':
                                    this.notierror('subdomin ถูกใช้ไปแล้วกรุณาใช้ชื่อ subdomin อื่น')
                                    break;
                                case 'domain is already in used':
                                    this.notierror('subdomin ถูกใช้ไปแล้วกรุณาใช้งชื่อ subdomin อื่น')
                                    break;
                                case 'e-mail is already in used':
                                    this.notierror('email ถูกใช้ไปแล้วกรุณาใช้ email อื่น')
                                    break;
                                case 'user e-mail is already is used':
                                    this.notierror('email ถูกใช้ไปแล้วกรุณาใช้ email อื่น')
                                    break;
                                case 'company name is taken':
                                    this.notierror('ชื่อสถาบัน ถูกใช้ไปแล้วกรุณาใช้ชื่อ สถาบัน อื่น')
                                    break;
                                default:
                                    this.notierror('ไม่สามารถดำเนินการสมัครสมาชิก โปรดแจ้งผู้ดูแลระบบ')
                                    break;
                            }
                        }
                        else {
                        }

                    }).finally(() => {
                        this.overlay = false
                    });
                }
                else {
                    this.notierror('กรุณากรอกข้อมูลให้ครบ')
                }
            }
            else {
                this.notierror('กรุณากรอกรหัสผ่านให้ตรงกัน')
            }


        },
        insertUser(id) {
            return axios.post(`/account/tula/register/admin`, {
                username: this.systemEmail,
                password: this.password,
                firstName: "Admin",
                lastName: this.title,
                image: "",
                active: true,
                studentCode: "",
                gender: "male",
                email: this.systemEmail,
                identificationNumber: "",
                companies: [id], //
                user_groups: [],
                isTeacher: "y",
                permission: {
                    adminCompany: true,

                    viewTutor: true,
                    viewStudent: true, //ข้อมูลผู้ใช้
                    insertStudent: true, //เพิ่มรายชื่อ
                    updateStudent: true, //แก้ไขรายชื่อผู้ใช้
                    activeStudent: true, //ระงับการใช้งาน

                    viewCourse: true, //เข้าถึงคอร์ส
                    insertCourse: true, //เพิ่มคอร์ส
                    updateCourse: true, //แก้ไขคอร์ส
                    activeCourse: true, //ระงับคอร์ส

                    viewCategory: true, //จัดการ Category
                    insertCategory: true, //จัดการ Category
                    updateCategory: true, //จัดการ Category
                    activeCategory: true, //จัดการ Category

                    viewBanner: true, //จัดการ Banner
                    insertBanner: true, //จัดการ Banner
                    updateBanner: true, //จัดการ Banner
                    activeBanner: true, //จัดการ Banner

                    viewReport: true, //เข้าถึงการรายงาน

                    viewCompany: false,
                    insertCompany: false,
                    updateCompany: false,
                    activeCompany: false,

                    viewUserGroup: true, //จัดการกลุ่มผู้ใช้
                    insertUserGroup: true, //จัดการกลุ่มผู้ใช้
                    updateUserGroup: true, //จัดการกลุ่มผู้ใช้
                    activeUserGroup: true, //จัดการกลุ่มผู้ใช้

                    accessBI: false
                }
            })
        },
    }
}